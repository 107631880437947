<template>
  <div
    class="
      w-full
      flex flex-col
      items-center
      justify-center
      py-4
      h-screen
      bg-white
      dark:bg-jevi-dark
    "
  >
    <img src="@/assets/img/error/403.svg" alt="No autorizado" />
    <h1 class="text-2xl font-semibold text-primary-800 dark:text-white">
      {{ $i18n.t("user_disabled") }}
    </h1>
    <p class="text-primary-700 dark:text-white">
      {{ $i18n.t("user_disabled_t2") }}.
    </p>
    <t-button class="mt-4" @click="redirect()"
      >{{ $i18n.t("return") }} {{ $i18n.t("to_login") }}</t-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    redirect() {
      window.location.href = "/"
    }
  },
  created() {
    this.$acl.change("public")
  }
}
</script>
